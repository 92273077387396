import { DynamoDBClient } from "@aws-sdk/client-dynamodb";

import useUser from "./useUser";

export default function useDynamoDbClient() {
  const { credentials } = useUser();

  return new DynamoDBClient({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: credentials ?? undefined,
  });
}
