import { API } from "aws-amplify";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import useStyles from "./AccessRequest.styles";

export default function AccessRequest() {
  const classes = useStyles();
  const [granted, setGranted] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const apiGrant = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const challenge_id = urlParams.get("cid");
    console.log("GRANT", challenge_id);

    API.post("API", `/`, {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        Action: "RespondToAppAuthRequest",
        Payload: {
          ChallengeId: `${challenge_id}`,
          AllowAccess: true,
        },
      },
    })
      .then((data) => {
        console.log("API RESPONSE", data);
        setGranted(true);
      })
      .catch((err) => {
        console.log("API ERR", err);
        setError(true);
      });
  }, []);

  const apiDeny = useCallback(() => {
    // todo
  }, []);

  const onGrantClick = useCallback(() => {
    apiGrant();
  }, [apiGrant]);

  const onDenyClick = useCallback(() => {
    apiDeny();
  }, [apiDeny]);

  const onGoToClick = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  const additionalClasses =
    " MuiTypography-root makeStyles-title-6 MuiTypography-h6 MuiTypography-noWrap";

  if (error) {
    return (
      <div className={classes.contentContainer + additionalClasses}>
        <div>
          Error while granting!
          <br />
          Please close and request another token.
        </div>
      </div>
    );
  }

  if (granted) {
    return (
      <div className={classes.contentContainer + additionalClasses}>
        <div>
          Access granted.
          <br />
          <br />
          <button
            className={classes.btn + additionalClasses}
            onClick={onGoToClick}
          >
            Go to ImageHub
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.contentContainer + additionalClasses}>
      <div>
        Do you want to grant access?
        <br />
        <br />
        <button
          className={classes.btn + additionalClasses}
          onClick={onGrantClick}
        >
          Grant
        </button>
        <button
          className={classes.btn + additionalClasses}
          onClick={onDenyClick}
        >
          Deny
        </button>
      </div>
    </div>
  );
}
