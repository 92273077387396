import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  contentContainer: {
    position: "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
    zIndex: 1201,
    background: "white",
    alignContent: "center",
    textAlign: "center",
  },

  btn: {
    color: "white",
    background: "gray",
    padding: "5px 10px",
    border: "none",
    borderRadius: "2px",
    margin: "5px",
  },
}));
